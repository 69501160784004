import { init } from 'zipyai';

export function initZipy() {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ZIPY_KEY) {
    const shouldBlacklistDomainForZipy = () => {
      if (process.env.REACT_APP_ZIPY_BLACKLIST) {
        const domains = process.env.REACT_APP_ZIPY_BLACKLIST.split(',');
        return domains.includes(window.location.hostname);
      }
    };

    if (shouldBlacklistDomainForZipy()) {
      console.debug(`Not initializing zipy for ${window.location.hostname}`);
    } else {
      init(process.env.REACT_APP_ZIPY_KEY);
    }
  }
}
